<template>
  <div>
    <bread-crumb :crumbData="['销售订单', '新增订单']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <div style="
        margin: 20px;
        border: 1px solid #e5e5e5;
        background-color: #f5fafe;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 30px;
      ">
      <p style="padding: 10px 20px">销售订单</p>
      <el-button @click="commitAll" type="primary" size="mini" style="padding: 5px 15px 5px 10px">
        <div style="display: flex; align-items: center; font-size: 12px">
          <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
          <span style="margin-left: 3px">提交</span>
        </div>
      </el-button>
    </div>
    <div style="
        margin: 5px 20px;
        border: 1px solid #e5e5e5;
        background-color: #f5fafe;
      ">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        style="padding: 20px">
        <el-form-item label="客户" prop="member_name" style="width: 30%">
          <el-input v-model="ruleForm.member_name" placeholder="点击选择客户" @focus="handleOutDialog">
            <i slot="suffix" class="el-input__icon el-icon-img"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="收货信息" prop="address">
          <div style="display: flex; align-items: center">
            <el-select v-model="ruleForm.address" placeholder="添加地址" style="flex: 1">
              <el-option v-for="item in ruleForm.msgRegiones" :key="item" :label="item.location"
                :value="item.id"></el-option>
            </el-select>
            <div>
              <el-link class="el-icon-mg-10"><i class="el-input__icon el-icon-img"
                  style="margin-right: -5px"></i>复制地址</el-link>
              <el-link class="el-icon-mg-10" @click="addAddress"><i class="el-input__icon el-icon-img"
                  style="margin-right: -5px"></i>添加地址</el-link>
              <el-link class="el-icon-mg-10" @click="updateAddress"><i class="el-input__icon el-icon-img"
                  style="margin-right: -5px"></i>修改地址</el-link>
            </div>
          </div>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="配送站点：" prop="seit">
            <el-select v-model="ruleForm.seit" placeholder="请选择" @change="changeDeliveryRegiones">
              <el-option v-for="item in gridData.station" :key="item.id" :label="item.shop_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="送货人员：" prop="deliveryRegion" style="margin-left: 10%">
            <el-select v-model="ruleForm.deliveryRegion" clearable placeholder="请选择">
              <el-option v-for="item in ruleForm.deliveryRegiones" :key="item.id" :label="item.userName"
                :value="item.userName"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="支付状态：" prop="status">
            <el-select v-model="ruleForm.status" placeholder="请选择" clearable>
              <el-option v-for="item in statuses" :key="item" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式：" v-if="ruleForm.status == 1" style="margin-left: 10%">
            <el-select v-model="ruleForm.payment" placeholder="请选择" clearable>
              <el-option v-for="item in payment" :key="item.id" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>


        <el-form-item label="订单备注：" style="width: 60%">
          <el-input v-model="ruleForm.remark"> </el-input>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="订货方式：" prop="mode" style="width: 30%">
            <el-select v-model="ruleForm.mode" clearable placeholder="请选择">
              <el-option v-for="item in modes" :key="item.id" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原单号：" style="margin-left: 10%; width: 28%">
            <el-input v-model="ruleForm.odd"> </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div style="padding: 20px">
      <table class="table">
        <tr>
          <th v-for="(item, index) in tableth" :key="index">{{ item }}</th>
        </tr>
        <tr v-for="(item, index) in tabletd" :key="index">
          <td>{{ item.product_name }}</td>
          <td>{{ item.specifications }}</td>
          <td><el-input v-model="item.price" @input="totalUpdate" /></td>
          <td><el-input v-model="item.coupon" @input="totalUpdate" /></td>
          <td><el-input v-model="item.number" @input="totalUpdate" /></td>
          <td>{{ item.number }}</td>
          <td>{{ item.sales }}</td>
          <td>{{ (item.price * item.number - item.coupon).toFixed(2) }}</td>
          <td style="
              padding: 10px 20px;
              border: 0;
              border-bottom: 1px solid #d0cfd1;
              border-right: 1px solid #d0cfd1;
            ">
            <i class="el-icon-delete" @click="deleteProduct(index)"></i>
          </td>
        </tr>
        <tr v-if="this.tabletd.length == 0">
          <td :colspan="9" style="color: #666">没有数据</td>
        </tr>
        <tr>
          <td style="cursor: pointer" @click="productShow = true">
            <i class="el-icon-plus" />添加商品
          </td>
          <td :colspan="8" />
        </tr>
        <tr>
          <td :colspan="4">合计</td>
          <td>{{ totalNumber }}</td>
          <td></td>
          <td></td>
          <td>{{ total }}</td>
          <td></td>
        </tr>
      </table>
    </div>
    <!-- 会员选择弹窗 -->
    <el-dialog title="会员选择" :visible.sync="outerVisible">
      <div>
        <div style="display: flex; align-items: center; white-space: nowrap">
          <span>日期范围：</span>
          <el-input v-model="dialogQuery" placeholder="输入手机号,昵称,姓名" style="margin-right: 5px; width: 30%"></el-input>
          <el-button type="success" size="mini" @click="memberSearch">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
          <el-button type="success" size="mini" @click="memberSubmit">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">确定</span>
            </div>
          </el-button>
          <el-button type="success" size="mini" @click="handleIndialog">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">新增会员</span>
            </div>
          </el-button>
        </div>
        <div style="padding: 20px">
          <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange" @cell-dblclick="memberSubmit">
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column prop="member_name" sortable label="姓名">
            </el-table-column>
            <el-table-column prop="bind_phone" sortable label="手机号">
            </el-table-column>
            <el-table-column prop="address" sortable label="地址" show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span>显示第&nbsp;
              {{ tableData.length === 0 ? 0 : (memberPage - 1) * memberLength + 1 }}
              &nbsp;至&nbsp;
              {{ (memberPage - 1) * memberLength + tableData.length }}
              &nbsp;项结果，共&nbsp;{{ memberNum }}&nbsp;项</span>
            <!-- 分页 -->
            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <!-- <el-button>首页</el-button>
              <el-button>上页</el-button> -->
              <el-pagination @current-change="getTabList" background layout="prev, pager, next" :total="memberNum"
                :current-page="memberPage"></el-pagination>
              <!-- <el-button>下页</el-button>
              <el-button>末页</el-button> -->
            </div>
          </div>
        </div>
      </div>

      <el-dialog title="会员管理 > 会员资料 > 新增" :visible.sync="innerVisible" append-to-body>
        <memberAddGroup :is-tab="false" @close="onClose" v-if="innerVisible" />
      </el-dialog>
    </el-dialog>

    <AddProduct :productShow="productShow" @handleClose="handleClose" @addConfirm="addConfirm"
      @productClick="productClick" />

    <AddressDialog ref="addressDialog" @memberSubmit="memberSubmit" />


  </div>
</template>
<script>
import memberAddGroup from "@/components/main/initialValue6/label3_memberInformation/childrens/memberAddGroup.vue";
import AddProduct from "@/components/addProduct/addProduct.vue";
import AddressDialog from "@/components/main/initialValue3/label2_manualEntry/AddressDialog.vue";
import { siteList, getAddress, getTabList, memberSearch, commitOrder } from "@/api/salesManagement/manualDeclaration.js";
export default {
  components: { memberAddGroup, AddProduct, AddressDialog },
  props: ["onClose"],
  data() {
    return {
      ruleForm: {
        member_name: "",
        msgRegion: "",
        seit: "",
        msgRegiones: [],
        station: "",
        deliveryRegion: "",
        deliveryRegiones: [],
        status: "",
        remark: "",
        mode: "",
        odd: "",
      },
      // 支付状态
      statuses: [
        { id: 1, label: "未支付", value: 0 },
        { id: 2, label: "已支付", value: 1 },
      ],
      // 配送方式
      modes: [
        { id: 1, label: "农夫", value: "0" },
        { id: 2, label: "饿了么水便利", value: "1" },
        { id: 3, label: "饿了么农工厂", value: "2" },
        { id: 4, label: "美团", value: "3" },
        { id: 5, label: "淘宝", value: "4" },
        { id: 6, label: "京东", value: "5" },
        { id: 7, label: "拼多多", value: "6" },
        { id: 8, label: "自提", value: "7" },
        { id: 9, label: "其他", value: "8" },
      ],
      // 支付方式 0为纸质水票  1为现金  2为微信  3为支付宝  4为记账  5为电子水票
      payment: [
        { id: 1, label: "纸质水票", value: "0" },
        { id: 2, label: "现金", value: "1" },
        { id: 3, label: "微信", value: "2" },
        { id: 4, label: "支付宝", value: "3" },
        { id: 5, label: "记账", value: "4" },
        { id: 6, label: "电子水票", value: "5" },
      ],
      rules: {
        member_name: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
        address: [{ required: true, message: "必填字段", trigger: "change" }],
        seit: [{ required: true, message: "必填字段", trigger: "change" }],
        // deliveryRegion: [{ required: true, message: "必填字段", trigger: "change" }],
        status: [{ required: true, message: "必填字段", trigger: "change" }],
        mode: [{ required: true, message: "必填字段", trigger: "change" }],

      },

      tableth: [
        "商品名称",
        "规格/颜色",
        "商品价格",
        "优惠券",
        "订货数量",
        "发货数量",
        "退货数量",
        "小计",
        "操作",
      ],
      tabletd: [],
      isResult: false,
      tableFinshNum: 0,

      outerVisible: false,
      dialogQuery: "",
      // 会员数据
      tableData: [],
      multipleSelection: [],
      isResult1: false,
      tableFinshNum1: 0,

      dialogTableVisible: false,
      gridData: [],
      multipleSelection1: [],
      isResult2: false,
      tableFinshNum2: 0,

      innerVisible: false,
      innerRuleForm: {
        phone: "",
        name: "",
        temporaryAddress: "",
        location1: "",
        location1es: [],
        location2: "",
        location2es: [],
        location3: "",
        location3es: [],
        detailedAddress: "",
        innerTableData: [],
        shop: "",
        shopes: [],
        type: "个人",
        desc: "",
      },
      innerRules: {
        phone: [{ required: true, message: "必填字段", trigger: "blur" }],
        name: [{ required: true, message: "必填字段", trigger: "change" }],
        temporaryAddress: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
        shop: [{ required: true, message: "必填字段", trigger: "change" }],
        type: [{ required: true, message: "必填字段", trigger: "change" }],
      },
      memberPage: 1,
      memberLength: 10,
      temporaryAddress: "",
      commercial_id: "",
      province: "",
      city: "",
      region: "",
      building: "",
      productShow: false,
      sitePage: 1,
      siteLength: 10,
      seit: "",
      seitName: "",
      userPage: 1,
      userLength: 10,
      memberNum: "",
      user: {},
      // 总订货数量
      totalNumber: 0,
      // 合计价格
      total: 0
    };
  },
  mounted() {
    if (this.tabletd.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tabletd.length;
    }
    if (this.tableData.length == 0) {
      this.isResult1 = false;
    } else {
      this.isResult1 = true;
      this.tableFinshNum1 = this.tableData.length;
    }
    if (this.gridData.length == 0) {
      this.isResult2 = false;
    } else {
      this.isResult2 = true;
      this.tableFinshNum2 = this.gridData.length;
    }
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
    this.getSiteList();
    this.memberSearch();
  },
  computed: {},
  watch: {
    tabletd() { this.totalUpdate() }
  },
  methods: {
    // 计算合计价格
    totalUpdate() {
      this.totalNumber = this.tabletd.reduce((sum, e) => { return sum + Number(e.number) }, 0)
      this.totalNumber = (this.totalNumber).toFixed(2)
      this.total = this.tabletd.reduce((sum, e) => sum + Number(e.price * e.number - e.coupon), 0)
    },
    handleOutDialog() {
      this.memberSearch();
      this.outerVisible = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleIndialog() {
      this.innerVisible = true;
    },
    fill() {
      this.temporaryAddress = this.innerRuleForm.temporaryAddress;
    },
    handleStationDialog() {
      this.dialogTableVisible = true;
    },
    handleGirdChange(val) {
      this.multipleSelection1 = val;
    },
    // 获取配送站点
    async getSiteList() {
      // const res = await this.$http.post("/system/pick_site/lst", {
      const res = await siteList(this.commercial_id)
      this.gridData = res.data;
    },
    // 配送站点对应的配送人员
    changeDeliveryRegiones(val) {
      const filter = this.gridData.admin.filter(
        (item) => item.store == val
      );
      this.ruleForm.deliveryRegiones = filter
    },
    // 获取会员数据
    async getTabList(now_page) {
      const res = await getTabList(now_page, this.memberLength)
      this.tableData = res.data.data;
      this.memberNum = res.data.count;
    },
    // async getUserList() {
    //   const res = await this.$http.post("/system/Employee/lst", {
    //     commercial_id: this.commercial_id,
    //     currentPage: this.userPage,
    //     currentLength: this.userLength,
    //   });
    // },
    handleClose() {
      this.productShow = false;
    },
    addConfirm(data) {
      this.productShow = false;
      const arr = data.map((item) => {
        return {
          product_id: item.product_id,
          specifications: item.specifications,
          price: item.price,
          coupon: 0,
          number: item.number,
          sales: 0,
        };
      });
      this.tabletd = [...this.tabletd, ...arr];
    },
    deleteProduct(index) {
      this.tabletd.splice(index, 1);
    },
    close() {
      this.innerVisible = false;
      this.getTabList();
    },
    // 搜索会员数据
    async memberSearch() {
      const res = await memberSearch(this.dialogQuery, this.commercial_id)
      this.tableData = res.data.data;
      this.tableFinshNum1 = res.data.count;
    },
    // 会员双击事件
    memberSubmit(row) {
      // if (this.multipleSelection.length === 0) return;
      this.$refs.addressDialog.row = row;
      this.commercial_id = row.commercial_id;
      this.getSiteList(this.commercial_id)
      getAddress(row.member_id).then((res) => {
        this.ruleForm = ""
        this.ruleForm = row
        this.ruleForm.msgRegiones = res.data;
        console.log(this.ruleForm.msgRegiones);
        console.log(res.data);
        this.ruleForm.address = ""
      })

      // this.user = this.multipleSelection[0];
      this.outerVisible = false;
    },
    // 添加地址
    addAddress() {
      this.$refs.addressDialog.addDialog(this.ruleForm);
    },
    // 修改地址
    updateAddress() {
      const filtermsgRegiones = this.ruleForm.msgRegiones.find(item => item.id === this.ruleForm.address)
      this.$refs.addressDialog.updateDialog(filtermsgRegiones);
    },
    // 子组件传入商品
    productClick(data) {
      data.coupon = 0;
      data.number = 1;
      data.price = data.sales_price;
      this.tabletd.push(data)
    },
    // 提交订单
    commitAll() {
      console.log(this.ruleForm.address);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let obj = {};
          const siteName = this.gridData.station.find(item => item.id === this.ruleForm.seit).shop_name;
          const phone = this.ruleForm.deliveryRegiones.find(item => item.userName === this.ruleForm.deliveryRegion)?.phone;
          obj.id = this.ruleForm.seit;
          obj.commercial_id = this.commercial_id;
          obj.member_id = this.ruleForm.member_id;
          obj.address_id = this.ruleForm.address;
          obj.site_name = siteName;
          obj.userName = this.ruleForm.deliveryRegion;
          obj.delivery_phone = phone;
          obj.pay_status = this.ruleForm.status;
          obj.payment = this.ruleForm.payment;
          obj.notes = this.ruleForm.remark;
          obj.ordering_method = this.ruleForm.mode;
          obj.json_list = this.tabletd
          obj = JSON.stringify(obj);
          commitOrder(obj).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.$props.onClose(0)
            } else {
              this.$message({
                message: "提交失败",
                type: 'error',
              });
            }
          });
        }
      });

    }
  },

};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;

  th {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }

  td {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }
}

.el-icon-img {
  background-image: url(~@/assets/iconImg/加号.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px;
}

.el-icon-mg-10 {
  margin: 0 10px;
}

.el-icon-mg-5 {
  margin: 0 -5px;
}

.my-dialog {
  position: relative;
  top: 50%;
  z-index: 2000;
}

.wt {
  width: 50% !important;
}

/deep/ .cell {
  padding: 0 !important;
}
</style>

  