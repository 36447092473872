<template>
    <div>
        <bread-crumb :crumbData="['余额统计']" :chooseCrumbData="2" @close="close"></bread-crumb>
        <el-col :span="16" style="margin:15px">
            <el-row>
                <el-col :span="8">
                    <span>创建日期：</span>
                    <el-date-picker v-model="query.firstTime" type="date" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-col :span="8">
                    <span>送货日期：</span>
                    <el-date-picker v-model="query.lastTime" type="date" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-input v-model="query.search" placeholder="请输入姓名、手机号或地址" style="width: 30%"></el-input>

                <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                    <div style="display: flex; align-items: center; font-size: 14px">
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                        <span style="margin-left: 3px">查询</span>
                    </div>
                </el-button>
            </el-row>
        </el-col>
        <div style="padding: 10px 20px">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                <el-table-column prop="member_id" label="编号"></el-table-column>
                <el-table-column prop="member_name" label="姓名"> </el-table-column>
                <el-table-column prop="bind_phone" label="手机号"> </el-table-column>
                <el-table-column prop="address" label="地址"></el-table-column>
                <el-table-column prop="consumption_monery" label="消费金额"> </el-table-column>
                <el-table-column prop="back" label="消费订单数"> </el-table-column>
                <el-table-column prop="back" label="购买商品数量"> </el-table-column>
            </el-table>
        </div>


    </div>
</template>
<script>
import { marketing } from "@/api/salesManagement/manualDeclaration.js";
import { getDate } from '../../../../until/utilse';
export default {
    props: ['close'],
    data() {
        return {
            query: {
                member_id: '',
                commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
                firstTime: '',
                lastTime: "",
                currentPage: 1,
                search: '',
                currentLength: 10
            },
            tableData: [],
        }
    },
    created() {
        // this.handleQuery()
    },
    mounted() {

    },
    computed: {

    },
    watch: {
        "query.member_id"(n) {
            this.handleQuery();
        }
    },
    methods: {
        async handleQuery() {
            let query = this.query;
            query.firstTime = getDate(this.query.firstTime);
            query.lastTime = getDate(this.query.lastTime);
            console.log(query)
            let res = (await marketing(query)).data;
            // let arr = res.pay.map(el => el.id);
            // console.log(res);
            // arr = arr.map(id => {
            //     let result = ({ payment: res.pay.find(el => el.id == id).payment, pay: res.pay.find(el => el.id == id)['SUM(sum)'], back: res['back'].find(el => el.id == id)['SUM(sum)'] });

            //     return result
            // })
            console.log(res, 'aaaaaa');
            this.tableData = res
        }
    },
}
</script>
<style scoped></style>
