<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['经营管理']" :chooseCrumbData="1"></bread-crumb>
      <!-- <search-case :searchData="searchData"></search-case> -->
      <div style="padding: 20px 20px 0; white-space: nowrap">
        <el-row>
          <el-col :span="7">
            <span>创建日期：</span>
            <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <span>订单状态：</span>
            <el-select v-model="query.status" clearable filterable placeholder="订单状态">
              <el-option v-for="item in order_status_options" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="7">
            <el-input v-model="query.search" placeholder="输入要查询的地址，联系人，手机号" style="width: 70%"></el-input>
            <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 3px">查询</span>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="create_time" sortable label="创建日期"></el-table-column>
          <el-table-column prop="commercial_account" sortable label="商户号"></el-table-column>
          <el-table-column prop="enterprise_name" sortable label="商户名称"></el-table-column>
          <el-table-column prop="person_charge" sortable label="负责人"></el-table-column>
          <el-table-column prop="service_number" sortable label="客服电话"></el-table-column>
          <el-table-column prop="commodity_sum" sortable label="上架商品数"></el-table-column>
          <el-table-column prop="status" sortable label="状态"></el-table-column>
          <el-table-column prop="region" sortable label="区域"></el-table-column>
          <el-table-column label="操作" min-width="420px">
            <template slot-scope="scope">
              <div style="
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
              ">
                <el-button size="mini" @click="member(scope.row)">会员统计</el-button>
                <el-button size="mini" @click="isShow = 2">余额统计</el-button>
                <el-button size="mini" @click="isShow = 3">订单统计</el-button>
                <el-button size="mini" @click="isShow = 4">收款统计</el-button>
                <el-button size="mini" @click="isShow = 5">送货统计</el-button>
                <el-button size="mini" @click="isShow = 6">商品统计</el-button>
                <el-button size="mini" @click="isShow = 7">商品库存</el-button>
                <el-button size="mini" @click="isShow = 8">空桶库存</el-button>
                <el-button size="mini" @click="isShow = 9">员工推广统计</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
          <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span>
          <div style="display: flex; justify-content: flex-end; align-items: center">
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>
    <memberInformation v-if="isShow == 1" :close="close"></memberInformation>
    <balance v-if="isShow == 2" :close="close"></balance>
    <sellOrder v-if="isShow == 3" :close="close"></sellOrder>
    <collection v-if="isShow == 4" :close="close"></collection>
    <deliver v-if="isShow == 5" :close="close"></deliver>
    <product v-if="isShow == 6" :close="close"></product>
    <goodsStock v-if="isShow == 7" :close="close"></goodsStock>
    <emptyBarrelInventory v-if="isShow == 8" :onClose="close"></emptyBarrelInventory>
    <promotion v-if="isShow == 9" :close="close"></promotion>
  </div>
</template>
<script>
import { getDate } from "@/until/utilse.js";
import { Operate_lst } from "@/api/salesManagement/manualDeclaration.js";

import sellOrder from "../../initialValue3/label3_sellOrder/sellOrder.vue"

import memberInformation from "../../initialValue6/label3_memberInformation/memberInformation.vue";
import goodsStock from "../../initialValue10/label2_goodsStock/goodsStock.vue";
import emptyBarrelInventory from "../../initialValue10/label4_emptyBarrelInventory/emptyBarrelInventory.vue";
import balance from "./balance.vue";
import collection from "./collection.vue";
import deliver from "./deliver.vue";
import product from "./product.vue";
import promotion from "./promotion.vue";
export default {
  async created() {
    this.handleQuery();
  },
  components: { memberInformation, balance, sellOrder, collection, deliver, product, goodsStock, emptyBarrelInventory, promotion },
  data() {
    return {
      isShow: 0,
      t1: null,
      query: {
        firstTime: "",
        lastTime: "",
        status: "",
        search: "",
        currentPage: 1,
        currentLength: 10
      },
      order_status_options: ["待审核", "已审核", "已关闭"],

      tableData: [{}],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    close() {
      // console.log('关闭')
      this.isShow = 0;
      this.handleQuery()
    },
    member() {
      this.isShow = 1
    },
    balance() {

    },
    indent() {
    },
    collection() {

    },
    deliver() {

    },
    commodity() {

    },
    inventory() {

    },
    empty() {

    },
    promotion() {

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async handleQuery() {
      let obj = {};
      if (this.t1) {
        this.query.firstTime = getDate(this.t1[0]);
        this.query.lastTime = getDate(this.t1[1]);
      } else {
        this.query.firstTime = "";
        this.query.lastTime = "";
      }
      obj = this.query;
      this.tableData = (await Operate_lst(obj)).data.data;
      console.log(this.tableData)
    },



  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}

/deep/ .el-radio-button__inner {
  border: 0 !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #409eff !important;
  box-shadow: none !important;
}
</style>
