<template>
    <div>
        <bread-crumb :crumbData="['商品统计']" :chooseCrumbData="2" @close="close"></bread-crumb>
        <el-col :span="16" style="margin:15px">
            <el-row>
                <el-col :span="8">
                    <span>创建日期：</span>
                    <el-date-picker v-model="query.firstTime" type="date" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-col :span="8">
                    <span>送货日期：</span>
                    <el-date-picker v-model="query.lastTime" type="date" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-input v-model="query.search" placeholder="请输入姓名、手机号或地址" style="width: 30%"></el-input>
                <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                    <div style="display: flex; align-items: center; font-size: 14px">
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                        <span style="margin-left: 3px">查询</span>
                    </div>
                </el-button>
                <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                    <div style="display: flex; align-items: center; font-size: 14px">
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                        <span style="margin-left: 3px">打印</span>
                    </div>
                </el-button>
                <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                    <div style="display: flex; align-items: center; font-size: 14px">
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                        <span style="margin-left: 3px">导出</span>
                    </div>
                </el-button>
            </el-row>
        </el-col>
        <div style="padding: 10px 20px">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                <el-table-column prop="product_name" label="品名"></el-table-column>
                <el-table-column prop="specifications" label="规格"> </el-table-column>
                <el-table-column prop="shipped" label="数量"> </el-table-column>
                <el-table-column prop="sum" label="金额"> </el-table-column>

                <!-- <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                            <el-button plain @click="infoBtn(scope.row)">详情</el-button>
                            <el-button plain style="margin: 5px 0" @click="handleRedact(scope.row)">编辑</el-button>
                            <el-button plain @click="handleReset(scope.row)">重置密码</el-button>
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- <div class="tableBottom">
                <span style="">显示第&nbsp;{{
                    tableData.length == 0 ? 0 : (page - 1) * length + 1
                }}&nbsp;至&nbsp;{{
    (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

                <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
                    <el-button @click="homePage">首页</el-button>
                    <el-button @click="upPage">上页</el-button>
                    <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
                        @current-change="currentChange"></el-pagination>
                    <el-button @click="nextPage">下页</el-button>
                    <el-button @click="endPage">末页</el-button>
                </div>
            </div> -->
        </div>


    </div>
</template>
<script>
import { product } from "@/api/salesManagement/manualDeclaration.js";
import { getDate } from '../../../../until/utilse';
export default {
    props: ['close'],
    data() {
        return {
            query: {
                commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
                firstTime: '',
                lastTime: "",
                search: "",
                currentPage: 1,
                currentLength: 10,
            },
            tableData: [],
        }
    },
    created() {
        this.handleQuery()
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        async handleQuery() {
            let query = this.query;
            query.firstTime = getDate(this.query.firstTime);
            query.lastTime = getDate(this.query.lastTime);
            let res = (await product(query)).data.data;
            this.tableData = res
            console.log(res);
        }
    },
}
</script>
<style scoped></style>
