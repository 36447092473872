<template>
  <div>
    <div v-show="isShow == 0">
      <bread-crumb :crumbData="['余额统计']" :chooseCrumbData="2" @close="hide"></bread-crumb>

      <div style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 20px 20px 0;
          white-space: nowrap;
        ">
        <el-row>
          <el-col :span="8">
            <span>创建日期：</span>
            <el-date-picker v-model="sData.creatTime" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <span>送货日期：</span>
            <el-date-picker v-model="sData.deliveryTime" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px; display: flex; flex-wrap: wrap">
          <el-col :span="8">
            <span>订单状态：</span>
            <el-select v-model="sData.orderStatus" clearable filterable placeholder="订单状态">
              <el-option v-for="item in orderStatus" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="sData.station" @change="changeDeliveryRegiones" clearable filterable placeholder="配送站点"
              style="margin: 0 5px">
              <el-option v-for="item in station" :key="item.id" :label="item.shop_name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="sData.delivery" clearable filterable placeholder="配送员">
              <el-option v-for="item in delivery" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="15">
            <el-input v-model="sData.queryContent" clearable placeholder="输入要查询的单号，地址，联系人，手机号"
              style="width: 70%"></el-input>
            <span style="margin-left: 5px">订货方式：</span>
            <el-select v-model="sData.orderingMethod" clearable filterable placeholder="订货方式">
              <el-option v-for="item in orderingMethod" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="margin-left: 5px">是否超时：</span>
            <el-select v-model="sData.overTime" clearable filterable placeholder="是否超时">
              <el-option v-for="item in overTime" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button @click="homePage" type="success" size="mini" style="margin-left: 5px">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 3px">查询</span>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div style="
          margin: 20px;
          padding: 5px 10px;
          border: 1px solid #eeeeee;
          background-color: #f5fafe;
          display: flex;
          align-items: center;
        ">
        <el-button type="primary" size="mini" @click="isShow = 1">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">新增订单</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="isShow = 3">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">补单</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="showBatchShip">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">批量发货</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">导出订单</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">批量打印小票</span>
          </div>
        </el-button>
      </div>
      <div style="
          font-size: 14px;
          margin: 20px;
          padding: 5px;
          border: 1px solid #eeeeee;
          background-color: #f5fafe;
        ">
        <span>订单状态：</span>
        <el-radio-group v-model="radioBtn" size="medium" @input="handleRadio">
          <el-radio-button label="全部" class="radioBtn">
            <span>全部</span>
            <el-badge :value="0" class="badge"></el-badge>
          </el-radio-button>
          <el-radio-button label="待付款" class="radioBtn">
            <span>待付款</span>
            <el-badge :value="0" class="badge"></el-badge>
          </el-radio-button>
          <el-radio-button label="待发货" class="radioBtn">
            <span>待发货</span>
            <el-badge :value="0" class="badge"></el-badge>
          </el-radio-button>
          <el-radio-button label="已发货" class="radioBtn">
            <span>已发货</span>
            <el-badge :value="0" class="badge"></el-badge>
          </el-radio-button>
          <el-radio-button label="待退款" class="radioBtn">
            <span>待退款</span>
            <el-badge :value="0" class="badge"></el-badge>
          </el-radio-button>
          <el-radio-button label="已完成" class="radioBtn">
            <span>已完成</span>
            <el-badge :value="0" class="badge" hidden></el-badge>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column sortable label="创建日期">
            <template slot-scope="scope">{{ scope.row.create_time }}</template>
          </el-table-column>
          <el-table-column prop="order_number" sortable label="订单编号">
          </el-table-column>
          <el-table-column prop="pay_number" sortable label="支付编号">
          </el-table-column>
          <el-table-column prop="member_name" label="会员"> </el-table-column>
          <el-table-column prop="consignee" label="收货人"> </el-table-column>
          <el-table-column prop="phone" label="联系人"> </el-table-column>
          <el-table-column prop="region" label="地区"> </el-table-column>
          <el-table-column prop="location" label="地址"> </el-table-column>
          <el-table-column prop="plan_time" sortable label="计划配送时间">
          </el-table-column>
          <el-table-column prop="ordering_method" sortable label="订单来源">
          </el-table-column>
          <el-table-column prop="number" sortable label="订单数量">
          </el-table-column>
          <el-table-column prop="sum" sortable label="应收金额">
          </el-table-column>
          <el-table-column prop="order_status" label="订单状态"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.$index, scope.row)" type="success" size="mini">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span>显示第&nbsp;
            {{ tableData.length === 0 ? 0 : (sData.pageNum - 1) * sData.pageSize + 1 }}
            &nbsp;至&nbsp;
            {{ (sData.pageNum - 1) * sData.pageSize + tableData.length }}
            &nbsp;项结果，共&nbsp;{{ sData.total }}&nbsp;项</span>
          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;">
            <el-button @click="homePage">首页</el-button>
            <el-pagination @current-change="saleSearch" background layout="prev, pager, next" :total="sData.total"
              :current-page="sData.pageNum"></el-pagination>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>

      <el-dialog title="订单导入" width="30%" :visible.sync="dialogFormVisible1">
        <el-form :model="dialogRuleForm1" :rules="dialogRules1" label-width="100px" class="demo-ruleForm">
          <el-form-item label="送货时间" prop="time">
            <el-date-picker v-model="dialogRuleForm1.time" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible1 = false">导 入</el-button>
        </div>
      </el-dialog>

      <el-dialog title="批量发货" width="30%" :visible.sync="batchShipDialog">确认发货吗？
        <!-- <el-form :model="dialogRuleForm2" :rules="dialogRules2" label-width="30%" class="demo-ruleForm">
          <el-form-item label="送达时间" prop="time">
            <el-date-picker v-model="dialogRuleForm2.time" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form> -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible">发 货</el-button>
        </div>
      </el-dialog>
    </div>

    <Add v-if="isShow == 1" :onClose="onClose"></Add>
    <Detail ref="detail" v-show="isShow == 2" :onClose="onClose"></Detail>
    <Replenishment v-if="isShow == 3" :onClose="onClose"></Replenishment>

  </div>
</template>

<script>
import { saleSearch, batchShip } from "@/api/salesManagement/salesOrder.js";
import Add from "./childrens/add.vue";
import Detail from "./childrens/details.vue";
import Replenishment from "./childrens/replenishment.vue";
import { siteList } from "@/api/salesManagement/manualDeclaration.js";

export default {
  components: { Add, Detail, Replenishment },
  props: ['close'],

  data() {
    return {
      hide: this.close || this.onClose,
      radioBtn: "全部",
      // 表格数据
      tableData: [],
      // 搜索数据
      sData: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
        creatTime: "",
        deliveryTime: "",
        orderStatus: "",
        station: "",
        delivery: "",
        queryContent: "",
        orderingMethod: "",
        overTime: "",
      },
      // 订单状态选项
      orderStatus: [
        { id: 1, label: "已付款", value: "0" },
        { id: 2, label: "待付款", value: "1" },
        { id: 3, label: "待发货", value: "2" },
        { id: 4, label: "已发货", value: "3" },
        { id: 5, label: "待退款/退款", value: "4" },
        { id: 6, label: "已完成", value: "5" },
        { id: 7, label: "已退款/退款", value: "6" },
        { id: 8, label: "已取消", value: "7" },
      ],
      // 配送站点及配送员保存
      gridData: "",
      // 配送站点选项
      station: [],
      // 配送员选项
      delivery: [],
      // 订货方式选项
      orderingMethod: [
        { id: 1, label: "线上订单", value: "0" },
        { id: 2, label: "人工报单", value: "1" },
        { id: 3, label: "农夫", value: "2" },
        { id: 4, label: "饿了么", value: "3" },
        { id: 5, label: "饿了么农工厂", value: "4" },
        { id: 6, label: "美团", value: "5" },
        { id: 7, label: "淘宝", value: "6" },
        { id: 8, label: "京东", value: "7" },
        { id: 9, label: "拼多多", value: "8" },
        { id: 10, label: "自提", value: "9" },
        { id: 11, label: "补单", value: "10" },
        { id: 12, label: "其它", value: "11" },
        { id: 13, label: "支付宝", value: "12" },
      ],
      // 超时选项
      overTime: [
        { id: 1, label: "已超时", value: "0" },
        { id: 2, label: "未超时", value: "1" },
      ],
      // 批量发货弹窗
      batchShipDialog: false,
      isResult: false,
      tableFinshNum: 0,

      dialogFormVisible1: false,
      dialogRuleForm1: {
        time: "",
      },
      dialogRules1: {
        time: [
          { required: true, message: "字段必填", trigger: "change" },
        ],
      },

      dialogFormVisible2: false,
      dialogRuleForm2: {
        time: "",
      },
      dialogRules2: {
        time: [
          { required: true, message: "字段必填", trigger: "change" },
        ],
      },
      // 子页面的显示
      isShow: 0,
      // 收集id
      idArr: [],
    };
  },
  created() {
    this.saleSearch()
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  methods: {
    // 获取配送站点
    async getSiteList() {
      const res = await siteList(this.commercial_id)
      this.gridData = res;
      this.station = res.data.station;
    },
    // 配送站点对应的配送人员
    changeDeliveryRegiones(val) {
      const filter = this.gridData.admin.filter(
        (item) => item.store == val
      );
      this.delivery = filter
    },
    // 关闭子页面
    onClose(data) {
      this.isShow = data;
      this.saleSearch();
    },
    // 刷新
    reload() {
      location.reload();
    },
    handleRadio() {
      console.log(this.radioBtn, "hh");
    },
    // id多选收集
    handleSelectionChange(val) {
      this.idArr = val.map(item => item.id);
      console.log(1, this.idArr)
    },
    // 批量发货按钮
    showBatchShip() {
      if (this.idArr.length == 0) {
        this.$message({
          message: "请选择数据",
          type: "warning",
        });
      } else {
        this.batchShipDialog = true;
      }
    },
    // 发货确认
    dialogFormVisible() {
      batchShip(this.idArr).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.saleSearch();
          this.batchShipDialog = false;
        } else {
          this.$message({
            message: "提交失败",
            type: 'error',
          });
        }
      });
    },
    // 详情
    handleEdit(index, row) {
      console.log(index, row);

      this.$refs.detail.tableData = row;

      // console.log(this.$refs.detail.tableData);
      this.isShow = 2;
    },
    // 查询销售订单
    saleSearch() {
      console.log(this.sData, "查询");
      saleSearch(this.sData).then(res => {
        this.tableData = res.data.data;
        this.sData.total = Number(res.data.count);
        console.log(res, "查询销售订单")
      })
    },
    // 首页
    homePage() {
      this.sData.pageNum = 1;
      this.saleSearch();
    },
    // 末页
    endPage() {
      this.sData.pageNum = Math.ceil(this.sData.total / this.sData.pageSize);
      this.saleSearch();
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

.radioBtn {
  margin-right: 10px;
}

.badge {
  position: absolute;
  top: -5px;
}

/deep/ .el-radio-button__inner {
  border: 0 !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #409eff !important;
  box-shadow: none !important;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
