<template>
    <div>
        <div v-if="isShow == 0">
            <div>
                <bread-crumb :crumbData="['商品统计']" :chooseCrumbData="2" @close="hide"></bread-crumb>
                <el-col :span="16" style="margin:15px">
                    <el-row>
                        <el-col :span="8">
                            <span>创建日期：</span>
                            <el-date-picker v-model="query.firstTime" type="date" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="8">
                            <span>送货日期：</span>
                            <el-date-picker v-model="query.lastTime" type="date" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                        <el-input v-model="query.search" placeholder="请输入姓名、手机号或地址" style="width: 30%"></el-input>
                        <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                            <div style="display: flex; align-items: center; font-size: 14px">
                                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                                <span style="margin-left: 3px">查询</span>
                            </div>
                        </el-button>
                        <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                            <div style="display: flex; align-items: center; font-size: 14px">
                                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                                <span style="margin-left: 3px">导出</span>
                            </div>
                        </el-button>
                    </el-row>
                </el-col>
                <div style="padding: 10px 20px">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                        <el-table-column prop="member_id" label="编号"></el-table-column>
                        <el-table-column prop="name" label="推广一级姓名"> </el-table-column>
                        <el-table-column prop="shipped" label="推广策略"> </el-table-column>
                        <el-table-column prop="bind_phone" label="手机号"> </el-table-column>
                        <el-table-column prop="wallet_balance" label="余额"></el-table-column>
                        <el-table-column prop="specifications" label="推广二级数量"> </el-table-column>
                        <el-table-column prop="shipped" label="推广会员数量"> </el-table-column>
                        <el-table-column prop="sum" label="推广会员分润"> </el-table-column>
                        <el-table-column prop="product_name" label="推广会员消费金额"></el-table-column>
                        <el-table-column prop="specifications" label="推广会员订单数"> </el-table-column>
                        <el-table-column prop="shipped" label="推广会员商品数量"> </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                                    <el-button plain @click="infoBtn(scope.row)">推广二级</el-button>
                                    <el-button plain style="margin: 5px 0" @click="show2(scope.row)">推广会员</el-button>
                                    <el-button plain @click="handleReset(scope.row)">分润详情</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <promoteMembership v-show="isShow == 1" :close="onClose" ref="child"></promoteMembership>
    </div>
</template>
<script>
import promoteMembership from "./promoteMembership.vue"
import { promotion } from "@/api/salesManagement/manualDeclaration.js";
import { getDate } from '../../../../until/utilse';
export default {
    props: ['close'],
    components: { promoteMembership },
    data() {
        return {
            isShow: 0,
            hide: this.close || this.onClose,
            query: {
                commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
                firstTime: '',
                lastTime: "",
                search: "",
                currentPage: 1,
                currentLength: 10,
            },
            tableData: [],
        }
    },
    created() {
        this.handleQuery()
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        show2({ member_id }) {
            let temp1 = this.$refs;
            this.$refs['child'].$data.query.member_id = member_id
            this.isShow = 1
        },
        onClose() {
            this.isShow = 0
        },
        async handleQuery() {
            let query = this.query;
            query.firstTime = getDate(this.query.firstTime);
            query.lastTime = getDate(this.query.lastTime);
            let res = (await promotion(query)).data;
            this.tableData = res
            console.log(res);
        },
        promoteMembership() {

        }
    },
}
</script>
<style scoped></style>
