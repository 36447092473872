<template>
    <div>
        <bread-crumb :crumbData="['余额统计']" :chooseCrumbData="2" @close="close"></bread-crumb>
        <el-col :span="7" style="margin:15px">
            <el-input v-model="keyword" placeholder="请输入姓名、手机号或地址" style="width: 70%"></el-input>
            <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery">
                <div style="display: flex; align-items: center; font-size: 14px">
                    <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                    <span style="margin-left: 3px">查询</span>
                </div>
            </el-button>
        </el-col>

        <div style="padding: 10px 20px">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                <el-table-column prop="member_id" label="编号"></el-table-column>
                <el-table-column prop="member_name" label="姓名"> </el-table-column>
                <el-table-column prop="bind_phone" label="手机号"> </el-table-column>
                <el-table-column prop="user_type" label="用户类型"></el-table-column>
                <el-table-column prop="address" label="地址"> </el-table-column>
                <el-table-column prop="card_balance" label="余额">
                </el-table-column>

                <!-- <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                            <el-button plain @click="infoBtn(scope.row)">详情</el-button>
                            <el-button plain style="margin: 5px 0" @click="handleRedact(scope.row)">编辑</el-button>
                            <el-button plain @click="handleReset(scope.row)">重置密码</el-button>
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- <div class="tableBottom">
                <span style="">显示第&nbsp;{{
                    tableData.length == 0 ? 0 : (page - 1) * length + 1
                }}&nbsp;至&nbsp;{{
    (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

                <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
                    <el-button @click="homePage">首页</el-button>
                    <el-button @click="upPage">上页</el-button>
                    <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
                        @current-change="currentChange"></el-pagination>
                    <el-button @click="nextPage">下页</el-button>
                    <el-button @click="endPage">末页</el-button>
                </div>
            </div> -->
        </div>

    </div>
</template>
<script>
import { balance } from "@/api/salesManagement/manualDeclaration.js";
export default {
    props: ['close'],
    data() {
        return {
            keyword: '',
            tableData: [],
        }
    },
    created() {
        this.handleQuery()
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        async handleQuery() {
            let res = (await balance({
                commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
                search: this.keyword,
                currentPage: 1,
                currentLength: 10

            })).data.data;
            this.tableData = res.map(el => {
                return {
                    ...el,
                    user_type: el.user_type ? "企业" : '个人'
                }
            })
        }
    },
}
</script>
<style scoped></style>
